<template>
  <div id="edit-test-app" class="form-horizontal">
    <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
    <div class="title-bar">
      <div class="pull-left center-float">
        <h1 id="heading" class="dark-text">
          {{ $t("_edit_test") }}
        </h1>
      </div>
    </div>
    <Form
      v-if="testRequestId"
      id="EditTest"
      key="EditTest"
      name="EditTest"
      :isEditing="true"
      :loadFormData="true"
      :getFormUrlParams="'idName=TestRequestId&id=' + testRequestId"
    />
  </div>
</template>

<script>
import Form from '../../../shared/components/Form.vue'
import EventBus from '@/app/shared/event-bus.js'
import html2canvas from 'html2canvas'
import Breadcrumbs from '../../../shared/components/Breadcrumbs.vue'
import { mapState, mapActions } from 'vuex'

window.html2canvas = html2canvas

export default {
  name: 'App',
  data: self => ({
    testRequestId: '',
    breadcrumbs: [
      {
        label: 'Dashboard',
        link: '/dashboard/overview',
        class: ''
      },
      {
        label: '_diagnostics',
        link: '/dashboard/diagnostics',
        class: ''
      },
      {
        label: '_edit_test',
        link: '',
        class: 'active'
      }
    ]
  }),
  mounted () {
    this.$nextTick(async function () {
      this.testRequestId = this.$route.params.testRequestId
    })
  },
  created () {
    EventBus.$on('TABLE_ROW_LENGTH', length => this.handleRowManipulation('TABLE_ROW_LENGTH', length))
    EventBus.$on('TABLE_ROW_ADDED', () => this.handleRowManipulation('TABLE_ROW_ADDED'))
    EventBus.$on('TABLE_ROW_REMOVED', () => this.handleRowManipulation('TABLE_ROW_REMOVED'))
    EventBus.$on('FORM_SAVE_SUCCESS', this.formSavedSuccessfully)
  },
  components: {
    Form,
    Breadcrumbs
  },
  computed: {
    ...mapState('Form', [
      'fieldsMappedByName',
      'existingData'
    ]),
    ...mapState('Patient', ['patientId'])
  },
  methods: {
    ...mapActions('Form', [
      'updateField'
    ]),
    formSavedSuccessfully () {
      this.$router.push(`/dashboard/patient/${this.existingData.episodeId}/Tests`)
    },
    handleRowManipulation (eventType, length) {
      const fieldName = 'TestResultDetailsresultSampleId'
      if (this.fieldsMappedByName && this.fieldsMappedByName.has(fieldName)) {
        let currentValue = this.fieldsMappedByName.get(fieldName).OptionsWithKeyValue || []
        if (eventType === 'TABLE_ROW_LENGTH') {
          const updatedValue = []
          for (let i = 0; i < length; i++) {
            const newKey = String(i)
            const newValue = `Sample-${i + 1}`
            updatedValue.push({ Key: newKey, Value: newValue })
          }
          currentValue = updatedValue
        } else if (eventType === 'TABLE_ROW_ADDED') {
          const newKey = String(currentValue.length)
          const newValue = `Sample-${currentValue.length + 1}`
          currentValue.push({ Key: newKey, Value: newValue })
        } else if (eventType === 'TABLE_ROW_REMOVED') {
          currentValue.pop()
        }
        this.updateField({
          key: fieldName,
          attribute: 'OptionsWithKeyValue',
          data: currentValue
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#edit-test-app {
  background-color: var(--background-bg-color);
}
* {
  margin: revert;
  box-sizing: unset;
}
</style>
